// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import useFetch from "../hooks/useFetch";

import { useState } from "react";
import useScrollToTop from "../../blocks/logic/usScrollToTop";
import PayPalWhiteboardBuy from "./PayPalWhiteboardBuy";

const WhiteboardProduct = ({
  name,
  slug,
  image,
  imageTwo,
  description,
  price,
}) => {
  useScrollToTop();

  console.log("WhiteboardProduct", imageTwo);

  const [selectedAmount, setSelectedAmount] = useState(1);
  const [priced, setPrice] = useState(150);
  const handleSelectChange = (event) => {
    setSelectedAmount(event.target.value);
    if (parseInt(event.target.value) === 1) {
      setPrice(150);
    } else if (parseInt(event.target.value) === 3) {
      setPrice(200);
    } else if (parseInt(event.target.value) === 5) {
      setPrice(400);
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url("${image}")`,
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              backgroundColor: "transparent",
              padding: "10%",
            }}
          >
            <div style={{ padding: "5%", backgroundColor: "whitesmoke" }}>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <h1>{name}</h1>
                {selectedAmount && <h2>$ {priced}</h2>}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <p style={{ textAlign: "center", fontSize: "1.5rem" }}>
                    EARLY BIRD SPECIAL!! $150 <br></br> PRE-ORDERS w/
                    CUSTOMIZATION
                  </p>
                  <li>
                    -- A dry erase 3 foot x 4 foot New Calendar Wall Map (ships
                    early 2025)
                  </li>
                  <li>
                    -- 10 customized date blocks (birthdays, anniversaries,
                    holidays, etc.)
                  </li>
                  <li>-- A pack of 4 fine tip dry erase markers</li>
                  <li>-- A classic user guide</li>
                  <li>-- Access to the New Calendar Discord</li>
                  <li>
                    -- A paper version of the 3 foot x 4 foot New Calendar Wall
                    Map (sent immediately)
                  </li>

                  <li>
                    **BONUS** the first 20 orders will receive an original first
                    edition error printing of The New Calendar with certificate
                    of authenticity signed by the design team (this is some real
                    future antiques roadshow stuff here)
                  </li>
                  <br></br>
                  <li>
                    ** UPGRADE ** to a professional-grade, unbranded map style
                    to gain 4 inches of space and discretely bring the New
                    Calendar into the office (comes with 20 custom dates)
                  </li>
                  {/* 
                  <p style={{ margin: "1%" }}>{description}</p> */}
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="amountSelector"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      margin: "5%",
                      width: "50%",
                    }}
                  >
                    <label htmlFor="quantity">Style: </label>
                    <select
                      id="quantity"
                      value={selectedAmount}
                      onChange={handleSelectChange}
                    >
                      <option value="1">Wall Map</option>
                      <option value="1">Wall Map</option>
                      <option value="3">Professional (unbranded)</option>
                      {/* <option value="5">Six-Sigma</option> */}
                    </select>
                  </div>
                  <PayPalWhiteboardBuy newPrice={priced} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url("${image}")`,
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            backgroundImage: `url("${imageTwo}")`,
            height: "100vh",
            backgroundSize: "100%",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </>
  );
};

export default WhiteboardProduct;
