import React from "react";
import { Route } from "react-router-dom";

import AdventureSquare from "./Pages/AdventureSquare";
import ProductPage from "./Pages/ProductPage";

import Blog from "./Pages/Blog";
import Media from "./Pages/Media";
import Home from "./Pages/Home";
import Shop from "./Pages/Shop";
// import UserPitch from "./Pages/UserPitch";
import NavBar from "./components/newdev/NavBar";
// import Footer from "./components/Footer";
import "./App.css";
import colorCode from "./blocks/logic/colorCode";
import Time from "./Pages/Time";
import RabbitHole from "./Pages/RabbitHole";
import Contact from "./Pages/Contact";
import Enterprise from "./Pages/Enterprise";
import PocketPDF from "./Pages/PocketPDF";
import About from "./Pages/About";
import ClassicProduct from "./Pages/products/ClassicProduct";
import MobileProduct from "./Pages/products/MobileProduct";
import WhiteboardProduct from "./Pages/products/WhiteboardProduct";
import PlannerProduct from "./Pages/products/PlannerProduct";
import DeskpadProduct from "./Pages/products/DeskpadProduct";
import WholesaleProduct from "./Pages/products/WholesaleProduct";
import CustomProduct from "./Pages/products/CustomProduct";
import SubscriptionProduct from "./Pages/products/SubscriptionProduct";

const App = () => {
  const seasonColor = colorCode([]);
  return (
    <>
      <NavBar />
      <div className="uniter">
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/media">
          <Media />
        </Route>
        <Route path="/how-to">
          <AdventureSquare />
        </Route>
        <Route path="/shop">
          <Shop />
          {/* <ProductPage /> */}
        </Route>

        {/* PRODUCTS */}
        <Route path="/newcalendar_classic">
          {/* <Shop /> */}
          <ClassicProduct
            price={20.23}
            name={"The New Calendar & User Guide"}
            slug={"newcalendar_classic"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700665978/EKJClknXYAgIzI2_pk6dy9.jpg"
            }
            description={
              "The classic New Calendar, 2019-9999 was developed and released in 2019 and has (and will be) operational until the year 10,000 (money back guarantee in the year 10000 if it stope working.)"
            }
          />
        </Route>
        <Route path="/newcalendar_mobileapp">
          {/* <Shop /> */}
          <MobileProduct
            price={100}
            name={"The New Calendar App"}
            slug={"newcalendar_mobileapp"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675716/_22889cb0-a0f6-4c9d-8caa-a70f63092a43_sk3kag.jpg"
            }
            description={
              "By far the biggest peice of feedback we've received over the past few years is that they want a digital, portable, mobile application that connects with their other various calendar applications for a quick and easy way to view their time in the new calendar format. We are planning to roll out this application in Winter 2024, and we are offering a pre-order discount of 50% off a LIFETIME subscription to the app."
            }
          />
        </Route>
        <Route path="/newcalendar_wallmap">
          {/* <Shop /> */}
          <WhiteboardProduct
            price={150}
            name={"New Calendar Wall Map"}
            slug={"newcalendar_wallmap"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1734522897/IMG_7101copycopy_z9ck6d.jpg"
            }
            imageTwo={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1734542869/IMG_7077crop_lghhnb.jpg"
            }
            description={
              "Want to unlock the full potential of The New Calendar? This 3'x4' dry erase wall map is the perfect tool to help you plan the rest of your years, today. Take control of your planning and goal setting with a great way to visualize your goals and progress."
            }
          />
        </Route>
        <Route path="/newcalendar_planner">
          {/* <Shop /> */}
          <PlannerProduct
            price={40}
            name={"Daily Planner"}
            slug={"newcalendar_planner"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675513/_57584c33-d7b0-4775-8adf-905426d8dc01_gpqqye.jpg"
            }
            description={
              "This Journal is designed to enable you to track measure and analyze time in a way never before possible. It is a great way to visualize your goals and progress. Comes with QR code to easily link you to exclusive content and the new calendar app for a streamlined analog/digital experience."
            }
          />
        </Route>
        <Route path="/newcalendar_deskpad">
          {/* <Shop /> */}
          <DeskpadProduct
            price={40}
            name={"New Calendar Deskpad"}
            slug={"newcalendar_deskpad"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675533/_3a29b4df-0447-475e-9b3b-b7320c467510_tonifj.jpg"
            }
            description={`"There's nothing better than a big ol' deskpad calendar," Woodrow Wilson once said. "It's the best way to keep track of your schedule, and it's the best way to keep track of your life." Now just imagine what Woody would about the New deskpad calendar.`}
          />
        </Route>
        <Route path="/newcalendar_wholesale">
          {/* <Shop /> */}
          <WholesaleProduct
            price={500}
            name={"Wholesale calendars"}
            slug={"newcalendar_wholesale"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700666741/5pack_lpzfbh-1to1_aoronc.jpg"
            }
            description={
              "The classic New Calendar, 2019-9999 was developed and released in 2019 and has (and will be) operational until the year 10,000 (money back guarantee in the year 10000 if it stope working.)"
            }
          />
        </Route>
        <Route path="/newcalendar_custom">
          {/* <Shop /> */}
          <CustomProduct
            price={50}
            name={"Customized Calendars"}
            slug={"newcalendar_custom"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700678144/_a233a786-f134-4362-86e9-ab4386591aa7_wm8cmz.jpg"
            }
            description={
              "Want to give a loved one some memories that will last a lifetime? Want to brand your business in the new calendar? Think you're forgetful nephew could use a calendar with everyone's birthday on it? We can do that."
            }
          />
        </Route>
        <Route path="/newcalendar_subscribe">
          {/* <Shop /> */}
          <SubscriptionProduct
            price={50}
            name={"The Seasonal Subscription Box"}
            slug={"newcalendar_subscribe"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700681561/_ea69bff5-2ff8-4dfc-aafc-0e9b704b4daf_cwyrij.jpg"
            }
            description={
              "Let the New Calendar come to you in the form of a seasonal subscription box. Each box will contain a new calendar, a new journal, and a new deskpad. Each box will also contain a new surprise gift."
            }
          />
        </Route>

        {/* RABBIT HOLE */}
        <>
          <Route path="/time">
            <Time />
            {/* <About /> */}
          </Route>
        </>
        <Route path="/rabbithole/money">
          <AdventureSquare
            rabbitData={{
              slug: "money",
              quote: `"Time is money."`,
              quoteAttribute: `-- Benjamin Franklin`,
              paragraph:
                "Time is the only resource every person has equal access to daily. How efficiently and effectively a person manages their time is so crucial to wealth, health, and happiness. The New Calendar helps users achieve this state by organizing time in the most precise, accurate, logical, and natural system ever developed in the history of time. Time is money: measure it well and spend it wisely.",
              productOne: "office",
              productTwo: "sourcecode",
              productThree: "enterprisesolutions",
              season: { seasonColor },
              nextHole: "revolution",
            }}
          />
        </Route>
        <Route path="/rabbithole/office">
          <ProductPage />
        </Route>
        <Route path="/rabbithole/sourcecode">
          <ProductPage />
        </Route>
        <Route path="/rabbithole/enterprisesolutions">
          <Enterprise />
        </Route>
        <Route path="/rabbithole/measurement">
          <AdventureSquare
            rabbitData={{
              slug: "measurement",
              quote: `"You cannot manage what you cannot measure."`,
              quoteAttribute: `-- Bill Hewlett`,
              // need to change this paragraph
              paragraph: `Time, in its simplest form, is a measurement of distance. Humans developed time as a concept in order to track, analyze, and record (i.e. measure) significant moments in Earth's orbit-- the day, the full moon, and the year-- all of which at their core, represent the distance it takes to a complete a full orbit. Until The New Calendar, time was the only measurement system built and last updated before the Scientific Revolution, which is why the Old Calendar seems more like a mystical substance than a true measurement.`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "frequency",
            }}
          />
        </Route>
        <Route path="/rabbithole/mystery">
          <AdventureSquare
            rabbitData={{
              slug: "mystery",
              quote: `"Time is still the great mystery to us. It is no more than a concept; we don't know if it even exists."`,
              quoteAttribute: `-- Clifford D. Simak`,
              paragraph: `Time is often treated as a mysterious, mystical substance that we cannot describe or know. This is because since the dawn of time, its mechanisms and maintenance was left to mystics and politicians. From Roman priestesses to Popes, the rulers of our time have made certain we observe time in a confusing manner (either by nefarious design or unwitting buffoonery, take your choice). Thank goodness a team of American scientists with The New Calendar spent a decade researching and developing a timekeeping system built to help the people use their time wisely, not subjugate them to it.`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "confusing",
            }}
          />
        </Route>
        <Route path="/rabbithole/confusing">
          <AdventureSquare
            rabbitData={{
              slug: "confusing",
              quote: `"Truth is ever to be found in simplicity, and not in the multiplicity and confusion of things."`,
              quoteAttribute: `-- Isaac Newton`,
              paragraph: `Time can be a source of confusion for people due to its multifaceted and abstract nature. It is subjectively perceived, often feeling either fast or slow depending on circumstances. The complexities of maintaining an over 3000-year-old timekeeping system, and the relativistic principles of physics further contribute to this confusion. Philosophical debates about the nature of time, questions related to mortality and aging, and the challenges of effective time management also play a role in making time a perplexing concept for many. However, time can also be simple. For example, our team of scientists understood The New Calendar is ultimately a simple measurement of the Earth's orbit around the sun. If there are 365 days in a year, the simplest and only way to divide that period is into its sole pair of base factors: 5 and 73. Therefore, instead of observing 4 seasons that range from 90-92 days long, depending on the season of the year, we should instead observe 5 seasons that are each 73 days long. The New Calendar is the first time in the history of time that we've divided time evenly using math, science, and logic.`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "science",
            }}
          />
        </Route>
        <Route path="/rabbithole/preciousResource">
          <AdventureSquare
            rabbitData={{
              slug: "preciousResource",
              quote: `"'Time is our most valuable non-renewable resource, and if we want to treat it with respect, we need to set priorities.'"`,
              quoteAttribute: `-- Albert-László Barabási`,
              // need to change this paragraph
              paragraph:
                "In essence, the quote serves as a reminder that time is a precious and finite resource, and to make the most of it, individuals should prioritize their actions and decisions, ensuring that they invest their time in activities and pursuits that align with their values and goals. It emphasizes the need for mindful time management and avoiding procrastination or trivial distractions that can waste this irreplaceable resource.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "money",
            }}
          />
        </Route>
        <Route path="/rabbithole/frequency">
          <AdventureSquare
            rabbitData={{
              slug: "frequency",
              quote: `"'If you want to find the secrets of the universe, think in terms of energy, frequency and vibration.'"`,
              quoteAttribute: `-- Nikola Tesla`,
              // need to change this paragraph
              paragraph:
                "offers a profound insight into the interconnectedness of time and the fundamental nature of the universe. Time, as a dimension, plays a critical role in understanding the universe's secrets through the lens of energy, frequency, and vibration. It suggests that everything in the cosmos, from subatomic particles to celestial bodies, is in a constant state of motion and change, oscillating with unique frequencies and energies. Time, as the fourth dimension, allows us to perceive and measure these dynamic processes. The quote encourages us to explore how time, as a backdrop, influences the intricate dance of energies, frequencies, and vibrations that shape the universe's hidden truths. In doing so, it beckons us to contemplate the timeless mysteries of existence and the role time plays in unraveling them.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "dimension",
            }}
          />
        </Route>
        <Route path="/rabbithole/humanConstruction">
          <AdventureSquare
            rabbitData={{
              slug: "humanConstruction",
              quote: `"'Time is a human construct.'"`,
              quoteAttribute: `-- [stoners at college parties]`,
              // need to change this paragraph
              paragraph:
                "And no matter how much they smell, they're not wrong. Time might be an inherent dimension of reality, but the tools and methods humans have created to measure and perceive time take many forms and are only as good as the underlying rules and systems that were embedded by their creators. So, as a society with unprecedented amount of access to the most modern science and math available, at a time in the world's history when it has never been more possible to stand up and question the systems that govern our lives, why are we still using a timekeeping system that was built and last updated before the Scientific Revolution? The New Calendar is the first time in the history of time that we've divided time evenly using math, science, and logic. This time, it's different.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "tool",
            }}
          />
        </Route>
        <Route path="/rabbithole/flatCircle">
          <AdventureSquare
            rabbitData={{
              slug: "flatCircle",
              quote: `"'Time is a flat circle.'"`,
              quoteAttribute: `-- Rust Cohle`,
              // need to change this paragraph
              paragraph:
                "In essence, 'time is a flat circle' embodies a bleak and pessimistic view of existence, suggesting that life's experiences are endlessly cyclical, with history and actions doomed to repeat, offering no genuine choices or meaningful progress. This concept reflects existential nihilism, portraying life as devoid of purpose and actions as fated to recur endlessly, serving as a thought-provoking and somewhat grim reflection on human existence and its repetitive patterns. It also suggests that time is a closed loop, with the past, present, and future all existing simultaneously, and that time is a human construct, a mere illusion that we use to make sense of our experiences. And when you use the Gregorian Calendar, time might often feel that way, as its previous developers hard coded their own errors, misjudgements, and desires onto what should be a finely tuned scientific instrument that accurately measures the 4th dimension. The New Calendar breaks the cycle, and allows users to escape the mistakes of the past, and create a new future.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/tool">
          <AdventureSquare
            rabbitData={{
              slug: "tool",
              quote: `"We must use time as a tool, not as a couch."`,
              quoteAttribute: `-- John F. Kennedy`,
              // need to change this paragraph
              paragraph:
                "The quote underscores the importance of actively and purposefully engaging with time rather than passively allowing it to slip away. It encourages a proactive approach to life, urging us to make the most of the limited time we have. Instead of idly lounging on a metaphorical couch and letting time pass by, we should treat time as a valuable resource and instrument for achieving our goals, personal growth, and productivity. This quote serves as a reminder that time is a precious asset that should be harnessed and leveraged to make a meaningful impact on our lives and the world around us.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/dimension">
          <AdventureSquare
            rabbitData={{
              slug: "dimension",
              quote: `"'If you want to find the secrets of the universe, think in terms of energy, frequency and vibration.'"`,
              quoteAttribute: `-- Nikola Tesla`,
              // need to change this paragraph
              paragraph:
                "Time is considered a dimension because, much like the three familiar spatial dimensions, it possesses properties that allow us to measure it, assign coordinates to events, conceptualize its motion, and incorporate it into mathematical frameworks alongside spatial dimensions. Just as objects move through space, events progress through time, and our experience of time is relative, as demonstrated by Einstein's theory of relativity. In the realm of physics, time plays a crucial role in describing the behavior of the universe and is integrated into theories like spacetime, where it forms a four-dimensional framework alongside spatial dimensions, making it an essential component of our understanding of the cosmos.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "flatCircle",
            }}
          />
        </Route>
        <Route path="/rabbithole/science">
          <AdventureSquare
            rabbitData={{
              slug: "science",
              quote: `"Time and space are not conditions of existence, time and space is a model for thinking."`,
              quoteAttribute: `-- Albert Einstein`,
              // need to change this paragraph
              paragraph:
                "The science of time is called metrology, and it is the most important science in the world. Metrology is the science of measurement, and it is the foundation of all other sciences. Without metrology, there would be no science, no technology, no engineering, no math, no physics, no chemistry, no biology, no astronomy, no medicine, no economics, no history, no philosophy, no religion, no psychology, no sociology, no anthropology, no politics, no law, no music, no art, no literature, no language, no culture, no civilization, no humanity, no life, no universe, no nothing.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "everything",
            }}
          />
        </Route>
        <Route path="/rabbithole/everything">
          <AdventureSquare
            rabbitData={{
              slug: "everything",
              quote: `"Timing is everything."`,
              quoteAttribute: `-- [unknown]`,
              // need to change this paragraph
              paragraph:
                "Timing is everything because it shapes the outcomes of our endeavors and the course of our lives. Just as a conductor orchestrates a symphony, the choices we make and when we make them can harmonize or clash with the circumstances around us. A well-timed decision can lead to success, while poor timing can result in missed opportunities or setbacks. It's akin to catching the perfect wave while surfing – if you paddle too early or too late, you may miss the ride. Whether in personal relationships, business ventures, or pursuing one's dreams, understanding the rhythm of time and acting in sync with it can make all the difference.",
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
            }}
          />
        </Route>
        <Route path="/rabbithole/scam">
          <AdventureSquare
            rabbitData={{
              slug: "scam",
              quote: `"The fraudsters greatest liability is the certainty that the fraud is too clever to be detected.'"`,
              quoteAttribute: `-- Louis Freeh`,
              // need to change this paragraph
              paragraph: `Just as fraudsters may falsely believe their schemes are foolproof, some previous calendar makers attempt to manipulate or distort calendars, timekeeping, or scheduling to their advantage, thinking they can go unnoticed. However, just like in the case of fraud, time and calendars have their own inherent systems and checks. Time, much like the certainty that fraudulent actions will eventually be uncovered, has an undeniable and reliable progression. Attempts to manipulate calendars or time are often unsustainable and can be easily exposed by those who pay attention to the consistency and accuracy of these systems. Thus, this quote serves as a reminder that time and calendars operate on principles that are difficult to deceive in the long run, and overconfidence in one's ability to manipulate them can lead to consequences. Not only is The New Calendar built upon the modern best practices in the field of metrology, but we are so confident in our work we guarantee each unit sold can be re-used every year from 2019 until 9999, or your money back!`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/ruler">
          <AdventureSquare
            rabbitData={{
              slug: "ruler",
              quote: `"Space, Einstein said, is merely what we measure with a ruler; time is what we measure with a clock."`,
              quoteAttribute: `-- Louisa Gilder`,
              paragraph: `There is a reason why the word 'ruler' means both a measuring device and a person in charge. It used to be that whoever was in charge got to determine what the standards were. A foot was the size of the King's foot, doesn't matter what anyone else suggested. Although unfair, the concept of a singular authority ruling over space and distance soon got abstracted into a scientific instrument, which began as a simple stick with notches and has grown into precision laser measurements. Unfortunately, time never got a chance to receive such care and attention. Instead of continuing to update and structure time with advances in modern math and science, the calendar is one of the few measurement systems that was not developed after the Scientific Revolution. Until, of course, 2017, when a team of American scientists discovered the 5th season of the year and redeveloped time as we know it.`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "revolution",
            }}
          />
        </Route>
        <Route path="/rabbithole/revolution">
          <AdventureSquare
            rabbitData={{
              slug: "revolution",
              quote: `"Better to die fighting for freedom than be a prisoner all the days of your life."`,
              quoteAttribute: `-- Bob Marley`,
              // need to change this paragraph
              paragraph: `Time is a revolution. Every minute, every hour, every day and every year is a full revolution, of either the clock or the Earth. And yet time seems to be the one thing you’re not allowed to question. It is the engine that has been running our society for nearly 3,000 years. Doesn’t it need a tune up? Better yet, isn’t it time to ditch the horse and chariot in favor of a rocket ship? Stop living in the past, the future is now. Come join the revolution, my friend.`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/flying">
          <AdventureSquare
            rabbitData={{
              slug: "flying",
              quote: `"Time flies.'"`,
              quoteAttribute: `-- Virgil`,
              // need to change this paragraph
              paragraph: `Time is a revolution`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/nature">
          <AdventureSquare
            rabbitData={{
              slug: "nature",
              quote: `"Nature is the source of all true knowledge.'"`,
              quoteAttribute: `-- Leonardo da Vinci`,
              paragraph: `Time is inextricably linked to nature, because time is a dimension of nature itself. Truly, according to scientists, the expanse of the entire Universe can only be measured with the help of time, and after rewinding 13.8 billion years or so, physicists cannot tell you what or how anything was happening before then`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole/illusion">
          <AdventureSquare
            rabbitData={{
              slug: "illusion",
              quote: `"People like us who believe in physics know that the distinction between past, present, and future is only a stubbornly persistent illusion.'"`,
              quoteAttribute: `-- Albert Einstein`,
              paragraph: `Time is an illusion, just like space, temperature, or any other perception we’ve created with our brains to sense and understand all the external inputs we receive, which we collectively call ‘the world.’ However, unless you can exist in this buddhist state of zen all day every day, where everything is nothing and nothing is everything, then time becomes a much more concrete aspect of your life. In the end, each day is really just a distance of about 584,000,000 miles that we travel each day during our orbital rotation around the Sun`,
              productOne: "moneyBall",
              productTwo: "moneyMONAY",
              productThree: "moneyTime",
              season: { seasonColor },
              nextHole: "measurement",
            }}
          />
        </Route>
        <Route path="/rabbithole">
          <RabbitHole />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/enterprise">
          <Enterprise />
        </Route>
        <Route path="/pocketpdf">
          <PocketPDF />
        </Route>
        {/* <Footer /> */}
        <NavBar />
      </div>
    </>
  );
};

export default App;
