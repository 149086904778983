import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import colorCode from "../../blocks/logic/colorCode";

const NavBar = () => {
  const history = useHistory();

  const [showNav, setShowNav] = useState(false);
  // let newClass = colorCode([]);
  // console.log("window", window.innerWidth, window.screen.width);
  const burgerNav = (event) => {
    setShowNav(!showNav);
    // console.log(showNav);
  };

  // let burgerClasses = ["burger", "border"];
  // console.log(newClass);

  const homeRoute = (event) => {
    history.push(`/`);
  };
  // const blogRoute = (event) => {
  //   history.push(`/blog`);
  // };
  const mediaRoute = (event) => {
    history.push(`/media`);
  };
  // const userpitchRoute = (event) => {
  //   history.push(`/how-to`);
  // };
  const shopRoute = (event) => {
    history.push(`/shop`);
  };
  const timeRoute = (event) => {
    history.push(`/time`);
  };
  const enterpriseRoute = (event) => {
    history.push(`/enterprise`);
  };
  const contactRoute = (event) => {
    history.push(`/contact`);
  };
  const sendEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "ATTN: Tom --";

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };
  return (
    <>
      <div className="app">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="logo"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700592696/New%20Calendar/dvrewjagmctry2f2r7xp.png")`,
              backgroundSize: "contain",
              height: "8vh",
              width: "10%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className="burger burgerBar burgerSquare"
            style={{ height: "8vh", width: "10%" }}
            onClick={burgerNav}
          ></div>
          <div
            className="navbar"
            style={{}}
            // onClick={burgerNav}
          >
            <div
              // className={newClass}
              className="routebar button-container"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="button"
                onClick={homeRoute}
                style={{ display: "flex", justifyContent: "center" }}
              >
                home{" "}
              </div>
              <div
                className="button"
                onClick={shopRoute}
                style={{ display: "flex", justifyContent: "center" }}
              >
                shop
              </div>
              {/* <div
                className="button"
                onClick={timeRoute}
                style={{ display: "flex", justifyContent: "center" }}
              >
                about
              </div> */}
              {/* <div className="button" onClick={enterpriseRoute}>
                media
              </div> */}
              <div
                className="button"
                onClick={mediaRoute}
                style={{ display: "flex", justifyContent: "center" }}
              >
                media
              </div>
              <div
                className="button"
                onClick={contactRoute}
                style={{ display: "flex", justifyContent: "center" }}
              >
                contact
              </div>
            </div>
            <div
              className="social_icons"
              style={{
                display: "flex",
                width: "30%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {/* <ul>tiktok</ul>
            <ul>fb</ul>
            <ul>insta</ul>
            <ul>discord</ul>
            <ul>email</ul> */}
              {/* <div className="flexRow" style={{ justifyContent: "space-evenly" }}> */}
              <a
                href="https://tiktok.com/@thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://tiktok.com/favicon.ico"
                  alt="TikTok"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <a
                href="https://instagram.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://static.cdninstagram.com/rsrc.php/v4/yR/r/lam-fZmwmvn.png"
                  alt="Instagram"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <div
                // target="_blank"
                // rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
                onClick={sendEmail}
              >
                <img
                  src="https://res.cloudinary.com/dqy3fktlv/image/upload/v1700595389/New%20Calendar/aw9m4thkss029ttcgafp.png"
                  alt="email"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </div>
              <a
                href="https://twitter.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://twitter.com/favicon.ico"
                  alt="Twitter"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <a
                href="https://facebook.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://facebook.com/favicon.ico"
                  alt="Facebook"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>

        {showNav ? (
          <div
            className="mobilenavbar"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              // height: "100vh",
              // backgroundColor: "white",
              // position: "absolute",
              // top: "0",
              // left: "0",
              // zIndex: "1000",
            }}
            // onClick={burgerNav}
          >
            <div
              // className={newClass}
              className="routebar button-container"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                className="button"
                onClick={homeRoute}
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                home
              </div>
              <div
                className="button"
                onClick={shopRoute}
                style={{ flex: 1, display: "flex", alignItems: "center" }}
              >
                shop
              </div>
              {/* <div
                className="button"
                onClick={timeRoute}
                style={{ flex: 1, display: "flex", alignItems: "center" }}
              >
                about
              </div> */}
              {/* <div className="button" onClick={enterpriseRoute}>
              media
            </div> */}
              <div
                className="button"
                onClick={mediaRoute}
                style={{ flex: 1, display: "flex", alignItems: "center" }}
              >
                media
              </div>
              <div
                className="button"
                onClick={contactRoute}
                style={{ flex: 1, display: "flex", alignItems: "center" }}
              >
                contact
              </div>
            </div>
            <div
              className="social_icons"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {/* <ul>tiktok</ul>
          <ul>fb</ul>
          <ul>insta</ul>
          <ul>discord</ul>
          <ul>email</ul> */}
              {/* <div className="flexRow" style={{ justifyContent: "space-evenly" }}> */}
              <a
                href="https://tiktok.com/@thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://tiktok.com/favicon.ico"
                  alt="TikTok"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <a
                href="https://instagram.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://static.cdninstagram.com/rsrc.php/v4/yR/r/lam-fZmwmvn.png"
                  alt="Instagram"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <div
                // target="_blank"
                // rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
                onClick={sendEmail}
              >
                <img
                  src="https://res.cloudinary.com/dqy3fktlv/image/upload/v1700595389/New%20Calendar/aw9m4thkss029ttcgafp.png"
                  alt="email"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </div>
              <a
                href="https://twitter.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://twitter.com/favicon.ico"
                  alt="Twitter"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              <a
                href="https://facebook.com/thenewcalendar"
                target="_blank"
                rel="noreferrer"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src="https://facebook.com/favicon.ico"
                  alt="Facebook"
                  style={{ height: "4vh", backgroundColor: "transparent" }}
                />
              </a>
              {/* </div> */}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NavBar;
