import React from "react";
// import SingleCalendarBuy from "../components/SingleCalendarBuy";
// import BulkCalendarBuy from "../components/BulkCalendarBuy";
import { Route } from "react-router-dom";

import ProductCompOne from "../components/ProductCompOne";
import ProductCompTwo from "../components/ProductCompTwo";
import colorCode from "../blocks/logic/colorCode";
import ShopCard from "../components/newdev/ShopCard";
import ProductPage from "./ProductPage";

const Shop = () => {
  // let seasonColor = colorCode([]);
  // let buttonColor = colorCode(["button free productText"]);
  let emailColor = colorCode(["productText free button"]);

  // const sendEmail = (event) => {
  //   const email = "tom@thenewcalendar.com";
  //   const subject = "Free Copy request";
  //   const body = `Hi Tom, I would like to request a free copy of The New Calendar and User Guide. I am interested in participating in this experiment because:`;
  //   const mailto = `mailto:${email}?subject=${subject}&body=${body}`;

  //   window.location.href = mailto;
  // };

  const contactEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "ATTN: Tom - Shop Issue -";

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };
  const customEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "Custom Order Inquiry:";

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Shop</h2>
        <div className="shop-card-container" style={{}}>
          <ShopCard
            name={"New Calendar Wall Map"}
            slug={"newcalendar_wallmap"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1734522897/IMG_7101copycopy_z9ck6d.jpg"
            }
            description={
              "Want to unlock the full potential of The New Calendar? This 3'x4' dry erase wall map is the perfect tool to help you plan the rest of your years, today. Take control of your planning and goal setting with a great way to visualize your goals and progress."
            }
          />
          <ShopCard
            name={"The New Calendar Classic"}
            slug={"newcalendar_classic"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700665978/EKJClknXYAgIzI2_pk6dy9.jpg"
            }
            description={
              "The New Calendar is a re-invention of the calendar based on the discovery of the 5th season of the year. The New Calendar is a new way to organize your time. It is a new way to think about time, your life, the world, and the universe."
            }
          />
          {/* <ShopCard
            name={"The New Calendar App"}
            slug={"newcalendar_mobileapp"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675716/_22889cb0-a0f6-4c9d-8caa-a70f63092a43_sk3kag.jpg"
            }
            description={
              "By far the biggest peice of feedback we've received over the past few years is that they want a digital, portable, mobile application that connects with their other various calendar applications for a quick and easy way to view their time in the new calendar format. We are planning to roll out this application in Winter 2024, and we are offering a pre-order discount of 50% off a LIFETIME subscription to the app."
            }
          /> */}

          {/* <ShopCard
            name={"Daily Planner"}
            slug={"newcalendar_planner"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675513/_57584c33-d7b0-4775-8adf-905426d8dc01_gpqqye.jpg"
            }
            description={
              "This Journal is designed to enable you to track measure and analyze time in a way never before possible. It is a great way to visualize your goals and progress. Comes with QR code to easily link you to exclusive content and the new calendar app for a streamlined analog/digital experience."
            }
          /> */}
          {/* <ShopCard
            name={"New Calendar Deskpad"}
            slug={"newcalendar_deskpad"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700675533/_3a29b4df-0447-475e-9b3b-b7320c467510_tonifj.jpg"
            }
            description={`"There's nothing better than a big ol' deskpad calendar," Woodrow Wilson once said. "It's the best way to keep track of your schedule, and it's the best way to keep track of your life." Now just imagine what Woody would about the New deskpad calendar.`}
          />
          <ShopCard
            name={"Wholesale calendars"}
            slug={"newcalendar_wholesale"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700666741/5pack_lpzfbh-1to1_aoronc.jpg"
            }
            description={
              "The classic New Calendar, 2019-9999 was developed and released in 2019 and has (and will be) operational until the year 10,000 (money back guarantee in the year 10000 if it stope working.)"
            }
          /> */}
          {/* <ShopCard
            name={"Customized Calendars"}
            slug={"newcalendar_custom"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700678144/_a233a786-f134-4362-86e9-ab4386591aa7_wm8cmz.jpg"
            }
            description={
              "Want to give a loved one some memories that will last a lifetime? Want to brand your business in the new calendar? Think you're forgetful nephew could use a calendar with everyone's birthday on it? We can do that."
            }
          /> */}
          {/* <ShopCard
            name={"The Seasonal Subscription Box"}
            slug={"newcalendar_subscribe"}
            image={
              "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700681561/_ea69bff5-2ff8-4dfc-aafc-0e9b704b4daf_cwyrij.jpg"
            }
            description={
              "Let the New Calendar come to you in the form of a seasonal subscription box. Each box will contain a new calendar, a new journal, and a new deskpad. Each box will also contain a new surprise gift."
            }
          /> */}
        </div>
      </div>
      {/* <div style={{ display: "flex" }}>
        <ProductCompOne />
        <ProductCompTwo />
        <br></br>
      </div>
      <div
        className={emailColor}
        style={{
          // width: "100%",
          border: "10px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={customEmail}
      >
        Custom calendars available! Email for details.
      </div> */}
      {/* <p>
        We are conducting a product test of a new pdf format. The first 50 users
        to respond will get a free copy for testing.
      </p>
      <div
        className={buttonColor}
        style={{
          // width: "100%",
          border: "10px dashed black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={sendEmail}
      >
        <h2 className={seasonColor}>
          Click to get your Free Copy and User Guide
        </h2>
      </div> */}
      {/* <div
        className={emailColor}
        style={{
          // width: "100%",
          border: "10px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={contactEmail}
      >
        issues? email: tom@thenewcalendar.com
      </div>{" "} */}
      <Route path="/thenewcalendar">
        <ProductPage />
        {/* <Shop /> */}
      </Route>
    </>
  );
};

export default Shop;
